import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Button from "react-bootstrap/Button";
import {
  headerTitle,
  inputFormContainer,
  walnutPrimaryButton,
} from "../components/layout.module.css";
import Layout from "../components/layout";
import { IIFormInput } from "./disclaimer";
import { VerifiedProductItem } from "./index";
import { IIIFormInput } from "./input-form";
import { navigate, PageProps } from "gatsby";

interface LocationState {
  productState: VerifiedProductItem;
  termState: IIFormInput;
  purchaseState: IIIFormInput;
}

const isBrowser = typeof window !== "undefined";
const uploadForm = ({ location }: PageProps) => {
  const stateData = location.state as LocationState;

  if (stateData) {
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
      const filesUploaded = event.currentTarget.files;
      if (filesUploaded && filesUploaded.length > 0) {
        navigate("/image-upload-form/", {
          state: {
            productState: stateData.productState,
            termState: stateData.termState,
            purchaseState: stateData.purchaseState,
            receiptImageFile: filesUploaded[0],
          },
        });
      }
    };

    const handleClick = (event: React.FormEvent<HTMLButtonElement>) => {
      hiddenFileInput.current?.click();
    };
    return (
      <Layout>
        <div className={inputFormContainer} style={{ textAlign: "center" }}>
          <h5 className={headerTitle}>Upload Receipt</h5>
          <StaticImage
            className="mt-3"
            alt="receipt"
            src="../images/receipt.png"
          />
          <p className="font-weight-bold mt-4">
            <strong>IMPORTANT</strong>
          </p>
          <p>
            Please make sure that you capture or upload the correct receipt.
            Invalid receipt will void the warranty and affect claim.
          </p>
          <div className="d-grid mt-5">
            <Button
              size="lg"
              className={walnutPrimaryButton}
              onClick={handleClick}
            >
              TAKE/UPLOAD PHOTO
            </Button>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              accept="image/png, image/jpeg, image/jpg"
              // capture="environment"
              size={5000000}
              style={{ display: "none" }}
            ></input>
          </div>
        </div>
      </Layout>
    );
  } else {
    if (isBrowser) {
      navigate("/", { replace: true });
    }
    return <Layout></Layout>;
  }
};

export default uploadForm;
